import React from "react";
import Layout from "../components/Layout";
import {
  TopHeader,
  Section,
  SectionText,
  TwoColumn,
} from "../components/Section";
import Seo from "../components/Seo";
import styled from "styled-components";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const FormSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 1rem;

  & > div {
    display: grid;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #9495a2;
  font-weight: 600;
`;

const Input = styled.input`
  display: block;
  padding: 0.8rem;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  outline-color: var(--dark-green);
  margin-bottom: 0.5rem;

  &:hover {
    border: 2px solid var(--dark-green);
  }
`;

const TextArea = styled.textarea`
  display: grid;
  grid-template-rows: 1fr;
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  font-family: inherit;
  margin-bottom: 1rem;
  outline-color: var(--dark-green);

  &:hover {
    border: 2px solid var(--dark-green);
  }
`;

const SubmitButton = styled.button`
  margin-top: 1rem;
  padding: 1.3rem 0.5rem 1.3rem 1rem;
  border: none;
  border-radius: 10px;
  background-color: var(--dark-green);
  color: white;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: 0.15s ease-out;

  &:hover {
    -webkit-box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.26);
    transform: scale(1.05);
    transition: 0.1s ease-in;
  }

  &:active {
    outline: none;
    transform: scale(0.9);
  }
`;

export default function ContactUs() {
  return (
    <Layout>
      <Seo
        title="Contact us"
        description="We'd love to hear from you! There are a couple of ways to contact us, so pick the one that works best for you, and let's start the conversation!"
      />
      <Section>
        <TopHeader>
          <h1>Contact Us</h1>
          <h2>We are open for more communication with you</h2>
        </TopHeader>
        <TwoColumn>
          <SectionText>
            <p>
              If you have a renovation project in mind, contact us
              for a free on-site evaluation of demolition and asbestos removal
              services in Vancouver!              
            </p>
            <p>You can also reach us in other ways:</p>
            <br />
            <p>
              <b>Call us</b>{" "}
            </p>
            <p>
              <a href="tel:778-340-8795">(+1) 778 340 8795</a>
            </p>
            <br />
            <p>
              <b>Email Us</b>
            </p>
            <p>
              <a href="mailto:info@addemolition.com">info@addemolition.com</a>
            </p>
          </SectionText>          
          <Form
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact"
          >          
            <FormSection>
              <div>
                <Label for="name">Name*</Label>
                <Input type="text" name="name" id="name" required />
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
              </div>
              <div>
                <Label for="email">Email*</Label>
                <Input type="email" name="email" id="email" required />
              </div>
            </FormSection>
            <FormSection>
              <div>
                <Label for="phone">Phone (optional)</Label>
                <Input type="tel" name="phone" id="phone" />
              </div>
              <div>
                <Label for="subject">Subject (optional)</Label>
                <Input type="text" name="subject" id="subject" />
              </div>
            </FormSection>

            <div>
              <Label for="message">Your Message*</Label>
              <TextArea name="message" id="message" rows="5" required />
            </div>
            <SubmitButton type="submit">Send</SubmitButton>
          </Form>
        </TwoColumn>
      </Section>
    </Layout>
  );
}
